<template>
  <div>
    <b-overlay :show="isLoading" variant="light" :opacity="0.75" blur="2px">
      <BreadcrumbCpn :breadcrumb="breadcrumb" class="mb-2" />
      <div
        v-if="isShowWarning"
        class="cs-message"
        style="background-color: rgb(168, 39, 39); color: white"
      >
        <div class="cs-header">
          <span class="font-bold text-xl">{{ $t("Thanh toán thất bại") }}</span>
          <button
            @click="() => (isShowWarning = false)"
            type="button"
            class="bg-transparent text-black fs-24 border-0 p-2"
            style="outline: none !important"
          >
            <span class="cursor-pointer text-white">&times;</span>
          </button>
        </div>
        <div class="flex gap-4 mt-1">
          <div class="flex items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M8.429 2.746a.5.5 0 0 0-.858 0L1.58 12.743a.5.5 0 0 0 .429.757h11.984a.5.5 0 0 0 .43-.757zm-2.144-.77C7.06.68 8.939.68 9.715 1.975l5.993 9.996c.799 1.333-.161 3.028-1.716 3.028H2.008C.453 15-.507 13.305.292 11.972l5.993-9.997ZM9 11.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-.25-5.75a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="flex fs-20 items-center">
            {{ $t(messageError) }}
            {{ $t("Vui lòng thanh toán lại đơn hàng.") }}
          </div>
        </div>
        <!-- <button
          class="btn bg-pri bd-pri text-white w-40 cs-button"
          @click="() => {}"
        >
          {{ $t("Thanh toán") }}
        </button> -->
      </div>
      <div class="order-detail">
        <div>
          <p class="text-xl">
            Thông tin Đơn hàng
            <span class="font-semibold">#{{ orderDetail.order_code }}</span>
          </p>
          <div class="border-bottom p-1 order-status">
            <ul
              class="list-unstyled multi-steps"
              v-if="
                orderDetail.product_delivery_configs &&
                orderDetail.status !== 100
              "
            >
              <li
                v-for="step in ORDER_DELIVERY_STATES"
                :key="step.id"
                class="step"
                :class="
                  step.order_number === orderDetail.status &&
                  step.order_number !== step.max_steps
                    ? 'is-active'
                    : step.order_number > orderDetail.status
                    ? 'after-step'
                    : 'before-step'
                "
              >
                <div class="step-icon">
                  <feather-icon
                    v-if="step.order_number < orderDetail.status"
                    icon="CheckIcon"
                    size="20"
                    class="mr-0"
                    style="top: 6px; left: 36%"
                  />
                  <span
                    v-if="step.order_number >= orderDetail.status"
                    class
                    style="font-weight: 700"
                    >{{ step.order_number }}</span
                  >
                </div>
                <div style="margin-top: 40px">
                  <span class="d-block">{{ step.title }}</span>
                  <small class="d-block text-secondary" v-if="step.date">{{
                    appUtils.formatDateTime(step.date)
                  }}</small>
                </div>
              </li>
            </ul>
            <div v-else class="flex justify-end">
              <b-badge variant="danger">Đơn hàng đã huỷ</b-badge>
            </div>
          </div>
          <div
            class="flex items-center p-1 product"
            v-for="(item, index) in orderDetail.order_items"
            :key="item.ID"
            :class="
              index < orderDetail.order_items.length - 1
                ? `border-bottom pb-1 mb-1`
                : ``
            "
          >
            <div class="flex justify-center product-img">
              <img
                :src="item.product.primary_image"
                alt
                class="w-24 h-24 object-cover rounded-md"
                @error="(e) => appUtils.onHandleErrorLoadImage(e)"
              />
            </div>
            <div class="product-info">
              <p class="product-name text-lg">
                {{ item.product && item.product.name }}
              </p>
              <div class="flex items-center justify-between product-quantity">
                <div class="rounded-md quantity">{{ item.qty }}</div>
                <p class="product-price mb-0">
                  {{ appUtils.numberFormat(item.price) }} VNĐ /
                  <span>{{ getVariant(item) }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="contact flex items-start justify-between gap-5 pt-4">
          <div class style="flex: 2">
            <p class="text-xl font-medium underline">
              Thông tin người nhận hàng
            </p>
            <el-form
              :model="receiver"
              :rules="ruleForm"
              ref="ruleForm"
              label-width="auto"
              label-position="top"
              class="hodo-el-form"
              disabled
              v-if="receiver"
            >
              <div class="row mb-2">
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('order_confirm.lbl_fullname')"
                    prop="name"
                  >
                    <el-input v-model="receiver.name"></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('order_confirm.lbl_phone_number')"
                    prop="phone"
                  >
                    <el-input v-model="receiver.phone"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <el-form-item :label="$t('order_confirm.lbl_address1')">
                    <el-input v-model="receiver.address1"></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('order_confirm.lbl_address2')"
                    prop="address2"
                  >
                    <el-input v-model="receiver.address2"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('order_confirm.lbl_email')"
                    prop="email"
                  >
                    <el-input v-model="receiver.email"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <div class="flex-1 gap-4">
            <p class="text-xl font-medium underline">Thông tin thanh toán</p>
            <div class="pb-1 w-full total">
              <div class="flex justify-between mb-1">
                <span>Tổng tiền</span>
                <span class="font-medium">{{
                  appUtils.numberFormat(totalAmount, "VND")
                }}</span>
              </div>
              <div class="flex justify-between mb-1">
                <span>Sử dụng điểm</span>
                <span class="font-medium"
                  >{{
                    orderDetail.voucher_info &&
                    appUtils.numberFormat(orderDetail.voucher_info.point_use)
                  }}
                  điểm ~
                  {{
                    orderDetail.voucher_info &&
                    appUtils.numberFormat(
                      orderDetail.voucher_info.discount_amount,
                      "VND"
                    )
                  }}</span
                >
              </div>
            </div>
            <div class="pt-1">
              <div
                class="text-right mb-2"
                :set="
                  (paymentInfo = handleGetPaymentInfo(
                    orderDetail.payment_status
                  ))
                "
              >
                <b-badge :variant="paymentInfo.variant">{{
                  paymentInfo.label
                }}</b-badge>
              </div>
              <div class="flex justify-between mb-1 text-xl font-semibold">
                <span>Tổng thanh toán</span>
                <span style="color: #20419b">{{
                  appUtils.numberFormat(finalTotal, "VND")
                }}</span>
              </div>
              <!-- <div class="btn btn-contact">Liên hệ với người bán</div> -->
              <div
                v-if="
                  orderDetail.payment_status ===
                    INTERNAL_PAYMENT_STATUS.UNPAID && isShowWarning
                "
                class="btn btn-cancel mb-2"
                style="background-color: #20419b; color: white"
                @click="handlePayment"
              >
                Thanh toán
              </div>
              <div
                class="btn btn-cancel"
                v-if="isShowBtnCancel"
                @click="handleCancelOrder"
              >
                Huỷ đơn hàng
              </div>
            </div>
            <p class="mt-1 text-center">
              Bằng việc tiến hành đặt mua hàng, bạn đồng ý với
              <span class="underline cursor-pointer">Điều khoản dịch vụ</span>,
              <span class="underline cursor-pointer"
                >Chính sách thu thập và xử lý dữ liệu cá nhân</span
              >
              của
              <span class="font-medium">{{ supplierName }}</span>
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import store from "@/store";
import appUtils from "@/utils/appUtils";
import i18n from "@/libs/i18n/index.js";
import OrderPersonInfo from "../../components/Ecom/OrderPersonInfo.vue";
import BreadcrumbCpn from "../../components/Layout/BreadcrumbCpn.vue";
import {
  INTERNAL_PAYMENT_STATUS,
  ORDER_DELIVERY_STATES,
  PAYMENT_TYPE,
  TRANSACTION_ERROR,
} from "@/utils/constant";
export default {
  name: "OrderDetail",
  components: {
    appUtils,
    OrderPersonInfo,
    BreadcrumbCpn,
  },
  data() {
    return {
      isShowWarning: false,
      isLoading: false,
      ORDER_DELIVERY_STATES,
      INTERNAL_PAYMENT_STATUS,
      orderDetail: [],
      order_code: "",
      messageError: "",
      ruleForm: {
        name: [
          {
            required: true,
            message: i18n.t("order_confirm.lbl_msg_require_receipient_name"),
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            message: i18n.t("order_confirm.lbl_msg_require_receipient_number"),
            trigger: ["blur", "change"],
          },
          {
            trigger: ["blur", "change"],
            validator(rule, value, callback) {
              if (isNaN(Number(value))) {
                callback(
                  new Error(i18n.t("order_confirm.lbl_msg_require_number"))
                );
              } else {
                callback();
              }
            },
          },
          {
            trigger: ["blur", "change"],
            validator(rule, value, callback) {
              if (
                value.toString().length < 10 ||
                value.toString().length > 12
              ) {
                callback(
                  new Error(
                    i18n.t("order_confirm.lbl_msg_phone_number_length_error")
                  )
                );
              } else {
                callback();
              }
            },
          },
          {
            pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
            message: i18n.t("message_errors.phone_invalidate"),
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            required: true,
            message: i18n.t("order_confirm.lbl_msg_require_email"),
            trigger: ["blur", "change"],
          },
        ],
        address2: [
          {
            required: true,
            message: i18n.t("order_confirm.lbl_msg_require_address2"),
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    supplierName() {
      return store.state.authenticate.userLogin?.supplierInfo?.name;
    },
    finalTotal() {
      if (this.orderDetail.voucher_info?.discount_amount) return 0;
      else
        return this.orderDetail.order_items
          ?.map((elm) => elm.price * elm.qty)
          ?.reduce((a, b) => a + b);
    },
    totalAmount() {
      return this.orderDetail.order_items
        ?.map((elm) => elm.price * elm.qty)
        ?.reduce((a, b) => a + b);
    },
    receiver() {
      return (
        this.orderDetail?.contact_order?.find((elm) => elm.type === 2) || null
      );
    },
    isShowBtnCancel() {
      return this.orderDetail.status === 1;
    },
    breadcrumb() {
      return [
        {
          text: this.$t("order_confirm.lbl_back"),
          to: {
            name: "UserProfile",
          },
        },
        {
          text: `Đơn hàng #${this.order_code}`,
        },
      ];
    },
  },
  watch: {
    $conectedEcho: {
      handler() {
        this.onConnectSocket();
      },
    },
  },
  async mounted() {
    await this.getOrderDetail();
    this.onConnectSocket();
  },
  methods: {
    async getOrderDetail() {
      return await store
        .dispatch("app-ecommerce/fetchOrderDetail", this.$route.params.id)
        .then((res) => {
          this.orderDetail = res.data;
          this.order_code = this.orderDetail?.order_code;

          if (res.data?.payment_history?.length) {
            const latestPayment = res.data?.payment_history[0];

            const responseCode =
              this.$route.query.vpc_TxnResponseCode ||
              latestPayment?.current_status;

            this.handleCheckResponsePayment(responseCode);
          }

          return res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getProduct(data) {
      return data?.data;
    },
    getVariant(item) {
      return item.variants?.ecom_unit?.name;
    },
    async cancelOrder() {
      await store
        .dispatch("app-ecommerce/cancelOrder", this.$route.params.id)
        .then((res) => {
          this.$message.success("Bạn đã huỷ đơn hàng này!");
          this.getOrderDetail();
        })
        .catch((e) => {
          this.$message.error("Có lỗi xảy ra, vui lòng thử lại!");
        });
    },
    async handleCancelOrder() {
      if (this.orderDetail.status < 2) {
        this.$confirm(
          this.$t(
            `Bạn có chắc chắn muốn huỷ đơn hàng <strong style='color:#20409B' >#${this.orderDetail.order_code}</strong> không?`
          ),
          this.$t("Xác nhận huỷ đơn hàng"),
          {
            cancelButtonText: this.$t("lbl_cancel"),
            confirmButtonText: this.$t("lbl_confirm"),
            dangerouslyUseHTMLString: true,
          }
        )
          .then((_) => {
            this.cancelOrder();
          })
          .catch((_) => {
            this.isChecked = false;
            this.$emit("onChange", false);
          });
      } else
        this.$message.error(
          "Đơn hàng đang được xử lý, bạn không thể huỷ đơn hàng này!"
        );
    },
    async onConnectSocket() {
      // const self = this
      // Have this in case you stop running your laravel echo server
      const eventName = this.$route.query.vpc_MerchTxnRef;

      // const eventName = "IMARMTcwODE4NjYxODg3Mg";
      const channelName = `internal_market.org${appUtils.getOrgId()}`;
      const self = this;

      if (this.$conectedEcho && this.$user && eventName) {
        // const user = this.$user

        const responseCode = this.$route.query.vpc_TxnResponseCode;
        if (
          self.orderDetail?.payment_status != PAYMENT_TYPE[1].value &&
          responseCode === "0"
        ) {
          this.isLoading = true;
        }

        this.$echo.channel(channelName).listen("EcomOPEvents", async (data) => {
          if (
            data?.data?.MetaData?.vpc_MerchTxnRef === eventName &&
            self.orderDetail?.payment_status != PAYMENT_TYPE[1].value
          ) {
            const response = await this.getOrderDetail();

            if (response.payment_status === PAYMENT_TYPE[1].value) {
              this.$message({
                message: this.$t("Thanh toán đơn hàng thành công"),
                type: "success",
              });

              this.$router.replace({
                name: "OrderDetail",
                params: {
                  id: this.$route.params.id,
                },
              });
            }

            setTimeout(() => {
              this.isLoading = false;
            }, 1000);
          }
        });
      }
    },
    handleGetPaymentInfo(status) {
      let paymentInfo = {
        variant: "",
        label: "",
      };

      switch (status) {
        case INTERNAL_PAYMENT_STATUS.PAID:
          paymentInfo = {
            variant: "light-success",
            label: "Đã thanh toán",
          };
          break;
        case INTERNAL_PAYMENT_STATUS.UNPAID:
          paymentInfo = {
            variant: "light-danger",
            label: "Chưa thanh toán",
          };
          break;

        default:
          break;
      }
      return paymentInfo;
    },
    handleCheckResponsePayment(responseCode) {
      if (responseCode && Number(responseCode) != 0) {
        this.isShowWarning = true;
        this.messageError =
          TRANSACTION_ERROR.find((item) => item.value === responseCode)
            ?.label || "";
        this.isLoading = false;
      }
    },
    async paymentWithOnePay(data) {
      if (!data?.id || !data?.org_service_transaction?.tx_code) return;

      try {
        const params = {
          orderType: 4, // Ecom marketplace
          orderId: data.id,
          tx_code: data?.org_service_transaction?.tx_code,
          returnUrl: `${window.location.origin}/#/orders/${data?.id}`,
        };

        const response = await this.$rf
          .getRequest("EcomRequest")
          .postRequestPayment(params);

        if (response.data?.url) {
          // appUtils.removeAllCartData();

          // this.$message({
          //   type: "success",
          //   message: this.$t("order_confirm.lbl_order_success_message"),
          // });
          window.location.replace(response.data.url);

          // window.open(response.data.url);
        } else {
          window.$toast.error({
            message: this.$t("Thanh toán đơn hàng thất bại"),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handlePayment() {
      this.$confirm(
        this.$t(`Bạn có chắc chắn muốn thanh toán đơn hàng không?`),
        this.$t("Xác nhận thanh toán"),
        {
          cancelButtonText: this.$t("lbl_cancel"),
          confirmButtonText: this.$t("lbl_confirm"),
          dangerouslyUseHTMLString: true,
        }
      )
        .then((_) => {
          this.paymentWithOnePay(this.orderDetail);
        })
        .catch((_) => {});
    },
  },
  setup() {
    return { appUtils };
  },
};
</script>

<style lang="scss" scoped>
.order-detail {
  background: #fff;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  color: #000;
  // width: 80%;
  // margin: 0 auto;
  .product {
    margin: 16px 40px;
    gap: 40px;

    .product-info {
      .product-quantity {
        width: 400px;
        .quantity {
          background-color: #f1f1f1;
          padding: 8px 40px;
        }
      }
    }
  }
  .contact {
    gap: 40px;
    .total {
      border-bottom: 1px solid #dbdbdb;
    }
    .btn {
      border-radius: 24px;
      width: 100%;
      font-weight: 600;
    }
    .btn-contact {
      background: #20419b;
      color: #fff;
      margin-bottom: 16px;
      &:hover {
        opacity: 0.9;
      }
    }
    .btn-cancel {
      border: 1px solid #000;
      color: #000;
      &:hover {
        background: #dbdbdb;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .product {
      align-items: flex-start;
      margin: 8px 0;
      gap: 20px;
      .product-img img {
        width: 60px !important;
        height: 60px !important;
      }
      .product-info {
        .product-name {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
        }
        .product-quantity {
          width: 100%;
          .quantity {
            padding: 4px 28px;
          }
        }
      }
    }
    .contact {
      flex-direction: column;
    }
  }
}
.step::before {
  content: "" !important;
  width: 0;
  height: 0;
  border: none;
  margin-bottom: 0;
}

.step-icon {
  width: 34px;
  height: 34px;
  background-color: #fff;
  border: 2px solid #476dd6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
  position: absolute;
  left: calc((100% - 36px) / 2);
}

.after-step {
  color: #808080;
  &::after {
    background-color: #808080;
  }
  .step-icon {
    border: 2px solid #808080;
  }
}
.step-active {
  color: #476dd6;
  &::after {
    background-color: #476dd6;
  }
}
::v-deep {
  .hodo-el-form {
    .el-form-item {
      &__label {
        width: 100%;
        line-height: 1;
      }
    }
  }
}

.cs-message {
  padding: 12px 20px !important;
  position: relative;
  border-radius: 8px;
  margin-bottom: 20px;

  .cs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      font-size: 30px;
      padding: 0 !important;
      line-height: 1;
    }
  }

  .cs-button {
    background-color: #20419b;
    padding: 8px !important;
    margin-left: auto !important;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
}
</style>